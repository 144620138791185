import React from 'react'
import styled from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import bg from '../images/footer_bg.jpg'
import Menu from './menu'
import NewsletterSignup from './newsletter-signup'

const Container = styled.div`
  max-width: 1200px;
  /* background: transparent url(${bg}) no-repeat center center; */
  background-color: rgba(22, 22, 22, .9);
  background-size: cover;
  padding: 25px;
  margin: 40px auto 0 auto;
  border-radius: 5px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const FooterLogo = styled.div`
  min-width: 150px;
  img {
    max-height: 140px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const Feature = styled.div`
  display: flex;
  padding: 0 50px 0 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 20px;
  }
  /* flex: 1; */
`
const FeatureContent = styled.div`
  margin-left: 25px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
`
const FeatureImage = styled.div`
  max-height: 140px;
  min-width: 150px;
  display: flex;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`
const FeatureTitle = styled.h3`
  margin-bottom: 15px;
  line-height: 1;
`
const FeatureDescription = styled.div`
  /* color: #fff; */
  line-height: 1.4rem;
  letter-spacing: 0;
`
const NewsletterContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
`
const LineBreak = styled.div`
  /* border-top: solid 1px rgba(255, 255, 255, 0.2); */
  margin: 40px 0;
`
const Bottom = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const BottomText = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -10px;
  padding-left: 15px;
  font-size: 0.9rem;
`

const Copyright = styled.div`
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.5);
`
const Daub = styled.div`
  a {
    color: rgba(46, 148, 111, 1);
    font: bold 14px/15px 'Roboto Condensed';
    letter-spacing: 0;
    text-transform: uppercase;
  }
  text-align: right;
  flex: 1;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        contentfulFooter {
          featureTitle
          featureLinkUrl
          featureLinkText
          featureDescription
          bottomText
          copyright
          featureImage {
            fluid(maxWidth: 150) {
              src
              srcSet
              sizes
              srcSetWebp
              srcWebp
              base64
              aspectRatio
            }
          }
          footerLogo {
            file {
              url
            }
          }
        }
      }
    `
  )
  const { contentfulFooter } = data
  return (
    <Container>
      <Top>
        {/* <FooterLogo>
          <img src={contentfulFooter.footerLogo.file.url} alt="Footer Logo" />
        </FooterLogo> */}
        <Feature>
          {contentfulFooter.featureImage && (
            <FeatureImage>
              <Img
                style={{ flex: 1 }}
                fluid={contentfulFooter.featureImage.fluid}
                alt="Feature Image"
              />
            </FeatureImage>
          )}
          <FeatureContent>
            <FeatureTitle>{contentfulFooter.featureTitle}</FeatureTitle>
            <FeatureDescription>
              {contentfulFooter.featureDescription}
            </FeatureDescription>
            <div style={{ marginTop: 15 }}>
              <Link to={contentfulFooter.featureLinkUrl}>
                {contentfulFooter.featureLinkText}
              </Link>
            </div>
          </FeatureContent>
        </Feature>
        <NewsletterContainer>
          <NewsletterSignup footer />
        </NewsletterContainer>
      </Top>
      <LineBreak />
      <Bottom>
        {/* <Menu isBottom /> */}
        <div>
          <BottomText>{contentfulFooter.bottomText}</BottomText>
          <Copyright>{contentfulFooter.copyright}</Copyright>
        </div>
        <Daub>
          <a href="http://daubcreative.com">
            Site Built with 💚
            <br /> by Daub Creative
          </a>
        </Daub>
      </Bottom>
    </Container>
  )
}

export default Footer
