import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import logo from '../images/header-logo.svg'

const Container = styled.div`
  background: rgba(34, 34, 34, 0.95) 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
`
const VerificationCard = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 5px;
  padding: 0 70px 30px 70px;
  max-width: 1024px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Logo = styled.img`
  margin-top: -40px;
  margin-bottom: 30px;
  max-width: 250px;
`
const Title = styled.div`
  font: bold 50px/60px 'Roboto Condensed';
  letter-spacing: 0;
  color: #222222;
  margin-bottom: 9px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    font-size: 30px;
    line-height: 38px;
  }
`
const SubTitle = styled.div`
  font: bold 26px/32px 'Roboto Condensed';
  letter-spacing: 0;
  color: #818181;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`
const Buttons = styled.div`
  /* display: flex;
  justify-content: center; */
  margin-top: 10px;
  a,
  button {
    background: #cdcdcd 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: black;
    border: none;
    font: bold 14px/19px 'Roboto';
    letter-spacing: 0;
    padding: 10px 30px;
    text-transform: uppercase;
  }
  button:first-child {
    margin-right: 10px;
  }
  button.action {
    background-color: #df7030;
    color: #fff;
  }
`
const AgeVerification = () => {
  const [cookies, setCookie] = useCookies(['ageVerify'])
  const [visible, setVisible] = useState(false)
  // setCookie('ageVerify', undefined, { path: '/' })
  useEffect(() => {
    setTimeout(() => {
      setVisible(cookies.ageVerify !== 'seen')
    }, 100)
  }, [cookies.ageVerify])

  const inAYear = new Date()
  inAYear.setDate(inAYear.getDate() + 365)

  return visible ? (
    <Container>
      <VerificationCard className="animated slideInDown">
        <Logo src={logo} alt="Age Verification Logo" />
        <Title>First Things First...</Title>
        <SubTitle>
          CINDER BLOCK BEER IS BREWED TO BE ENJOYED RESPONSIBLY BY ADULTS
        </SubTitle>
        <SubTitle style={{ color: '#DF7030', marginTop: 50 }}>
          Are you over 21?
        </SubTitle>
        <Buttons>
          <button
            type="button"
            className="action"
            onClick={() => {
              setCookie('ageVerify', 'seen', {
                path: '/',
                expires: inAYear,
              })
              setVisible(false)
            }}
          >
            Yes
          </button>
          <a href="https://disney.com">No</a>
        </Buttons>
      </VerificationCard>
    </Container>
  ) : (
    <></>
  )
}

export default AgeVerification
