import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  FaBars,
  FaInstagram,
  FaTwitter,
  FaFacebookSquare,
  FaTimes,
} from 'react-icons/fa'
import logo from '../images/header-logo.svg'

const Logo = styled.img`
  margin-top: -10px;
  max-width: 250px;
  @media (max-width: 1100px) {
    max-width: 200px;
  }
`
const MobileLogo = styled.img`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    width: 250px;
    position: absolute;
    top: 10px;
    left: calc((100% - 230px) / 2);
    z-index: 1001;
  }
`
const Container = styled.div`
  display: block;
  @media (max-width: 1024px) {
  }
`
const MenuContainer = styled.ul`
  list-style-type: none;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  align-items: top;
  display: flex;
  margin-top: ${({ bottom }) => (!bottom ? -2 : 0)}px;
  transition: 0.35s;
  transition-timing-function: ease;
  @media (max-width: 1024px) {
    position: absolute;
    background: #222;
    border-radius: 0 0 90% 0;
    padding: 50px;
    top: ${({ open }) => (open ? 0 : -500)}px;
    left: ${({ open }) => (open ? 0 : -500)}px;
    flex-direction: column;
    z-index: 999;
    padding-top: 120px;
    width: 100%;
    max-width: 414px;
    > li {
      margin-bottom: 10px;
    }
  }
`
const MenuItem = styled.li`
  padding: 0 15px;
  @media (max-width: 1100px) {
    /* padding: 0 10px; */
  }
  @media (max-width: 1024px) {
    padding-left: 0;
    margin-bottom: 5px;
    font-size: 1.1em;
    ${({ hasLogo }) => (hasLogo ? `display: none;` : ``)}
  }
  a {
    color: #df7030;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Roboto Condensed';
    letter-spacing: 0;
    &:active {
      color: #fff;
    }
  }
  padding-top: 2px;
`
const NavLink = styled(Link)`
  font-size: ${({ bottom }) => (!bottom ? '1.25rem' : '.9rem')};
  @media (max-width: 1050px) {
    font-size: 1rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.3rem;
  }
  &.active {
    color: #a3a569;
  }
`
const MenuButton = styled.button`
  background: none;
  border: none;
  display: none;
  outline: none;
  @media (max-width: 1024px) {
    position: absolute;
    top: 25px;
    left: 15px;
    display: block;
    z-index: 1000;
  }
`
const Hamburger = styled(FaBars)`
  color: #df7030;
`
const Close = styled(FaTimes)`
  color: #df7030;
`
const Social = styled.li`
  display: flex;
  align-items: flex-start;
  margin-right: 65px;
  @media (max-width: 1100px) {
    margin-right: 10px;
  }
`
const SocialLink = styled.a`
  margin-right: 15px;
`
const MainContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    ${({ bottom }) => (bottom ? `display: none;` : ``)}
  }
`
const Menu = ({ isBottom = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <MainContainer bottom={isBottom}>
      <MenuButton onClick={() => setIsOpen(!isOpen)} aria-label="Menu Button">
        {!isOpen ? <Hamburger fontSize={20} /> : <Close fontSize={20} />}
      </MenuButton>
      <Link to="/" title="Home">
        <MobileLogo src={logo} alt="Cinder Block Brewery Logo" />
      </Link>
      <Container>
        <MenuContainer open={isOpen}>
          <Social>
            <SocialLink
              href="https://www.instagram.com/cinderblockbrew/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram fontSize={18} color="#CB2F98" title="Instagram" />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/CinderBlockBrew"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare
                fontSize={18}
                color="#3B5998"
                title="Facebook"
              />
            </SocialLink>
            <SocialLink
              href="https://twitter.com/cinderblockbrew"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter fontSize={18} color="#1CA0F2" title="Twitter" />
            </SocialLink>
          </Social>
          <MenuItem>
            <NavLink to="/beer" activeClassName="active">
              Our Beer
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/brewery" activeClassName="active">
              Brewery
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/taproom" activeClassName="active">
              Taproom
            </NavLink>
          </MenuItem>
          {!isBottom && (
            <MenuItem hasLogo>
              <Link to="/" title="Home">
                <Logo src={logo} alt="Cinder Block Brewery Logo" />
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <NavLink activeClassName="active" to="/reclamation-room">
              Reclamation Room
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/happenings">
              Happenings
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/contact">
              Contact
            </NavLink>
          </MenuItem>
        </MenuContainer>
      </Container>
    </MainContainer>
  )
}

export default Menu
