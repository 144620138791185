import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { FaInstagram, FaTwitter, FaFacebookSquare } from 'react-icons/fa'

import Menu from './menu'

const TopBar = styled.header`
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000080;
  padding: 25px 0;
  height: 68px;
  position: sticky;
  top: 0;
  z-index: 2;
`

const TopBarInner = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
`

const Header = ({ siteTitle }) => (
  <TopBar>
    <TopBarInner>
      <Menu />
    </TopBarInner>
  </TopBar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
