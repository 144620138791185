import React, { useState } from 'react'
import styled from 'styled-components'
import { FaEnvelope } from 'react-icons/fa'

const Newsletter = styled.div`
  background-color: ${({ footer }) => (footer ? '' : '#222222')};
  padding: 10px 0;
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  ${({ footer }) =>
    footer
      ? `
    flex-direction: column;
    align-items: flex-start;
  `
      : ''}
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const NewsletterForm = styled.form`
  @media (max-width: 1024px) {
    width: 100%;
  }
`
const NewsletterInputContainer = styled.div`
  background: #1b1c1d;
  border-radius: 99px;
  margin-left: 15px;
  padding: 6px 20px;
  display: flex;
  @media (max-width: 1024px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  margin: ${({ footer }) => (footer ? '0 !important' : '')};
`
const NewsletterTextContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ footer }) =>
    footer
      ? `
      margin-bottom: 10px;
    text-align: center;
    justify-content: space-between;
  `
      : ''}
  @media (max-width: 1024px) {
    margin-bottom: 10px;
    text-align: center;
    justify-content: space-between;
  }
`
const NewsletterText = styled.div`
  font: bold 18px/18px 'Roboto Condensed';
  color: #fff;
  margin-left: 15px;
`
const NewsletterInput = styled.input`
  background: transparent;
  outline: none;
  border: none;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  margin-right: 5px;
  @media (min-width: 601px) {
    min-width: 250px;
  }
  width: 100%;
  /* min-width: 250px; */
`
const SubmitButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: #1ca0f2;
  font-size: 14px;
`

const NewsletterSignup = ({ footer = false }) => {
  const [state, setState] = useState({
    email: undefined
  })
  return (
    <Newsletter footer={footer}>
      <NewsletterTextContainer footer={footer}>
        <FaEnvelope color="#A3A569" fontSize={20} />
        <NewsletterText>
          JOIN US
          <span style={{ color: '#B4B4B4', fontWeight: 'normal' }}>
            ...FOR NEWS AND PROMOS
          </span>
        </NewsletterText>
      </NewsletterTextContainer>
      <NewsletterForm
        name="Newsletter Signup"
        method="POST"
        data-netlify="true"
      >
        <NewsletterInputContainer footer={footer}>
          <input type="hidden" name="form-name" value="Newsletter Signup" />
          <NewsletterInput
            aria-label="email"
            type="email"
            placeholder="Email Address"
            name="email"
            onChange={e => setState({ email: e.target.value })}
          />
          <SubmitButton type="submit" disabled={state.email === undefined}>
            Submit
          </SubmitButton>
        </NewsletterInputContainer>
      </NewsletterForm>
    </Newsletter>
  )
}

export default NewsletterSignup
