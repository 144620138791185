/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { CookiesProvider } from 'react-cookie'

import { isMobile } from 'react-device-detect'
import BackgroundImage from 'gatsby-background-image'
import Header from './header'
import './layout.css'
import Footer from './footer'
import 'animate.css'
import AgeVerification from './age-verification'

const Background = styled.div`
  background-image: ${({ bgImage }) =>
    `linear-gradient(to top, rgba(0,0,0,.65), rgba(0,0,0,.65), rgba(0,0,0,.65)),
    linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)),
    url('${bgImage}')
    `};
  /* ${isMobile ? `background: #121212;` : ``} */
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: bottom, bottom, center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
`

const MainContent = styled.main`
  margin: 0 auto;
  padding-top: 40px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const Layout = ({ children, bgImage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      contentfulAsset(id: { eq: "27efa644-0898-5e9b-ad54-cfd3f1f3a2dc" }) {
        file {
          url
        }
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const stack = [
    `linear-gradient(to top, rgba(0,0,0,.65), rgba(0,0,0,.65), rgba(0,0,0,.65))`,
    `linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0))`,
    (bgImage && bgImage.fluid) || data.contentfulAsset.fluid,
  ]

  return (
    <CookiesProvider>
      <AgeVerification />
      <Header siteTitle={data.site.siteMetadata.title} />
      <BackgroundImage
        fluid={stack}
        style={{
          backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
          backgroundposition: 'bottom, bottom, center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
        }}
      >
        <MainContent>{children}</MainContent>
        <footer></footer>
        <Footer />
      </BackgroundImage>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
